import { Dispatch, useState } from "react";
import { Textarea } from "@acdc2/ui/components/textarea";
import { Button } from "@acdc2/ui/components/button";
import { useTranslation } from "react-i18next";
import { EditorAction, PaneIdentifier, TabIdentifier } from "../../reducers";
import AttributesPane from "../AttributesPane";
import AttributesPaneControls from "../AttributesPaneControls";
import {
  TextCreationPaneFragment,
  useCreateTextAttributeMutation,
} from "../../../client/generated";

type Props = {
  dispatch: Dispatch<EditorAction>;
  fragment: TextCreationPaneFragment;
};

export default function TextCreationPane({
  dispatch,
  fragment,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const [textAttribute, setTextAttribute] = useState<string>();

  const [createTextAttribute] = useCreateTextAttributeMutation();

  const onSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (!textAttribute) return;

    await createTextAttribute({
      variables: { text: textAttribute, regionId: fragment.id },
    });

    dispatch({
      type: "switchPane",
      paneState: {
        tabIdentifier: TabIdentifier.Attributes,
        paneIdentifier: PaneIdentifier.Default,
      },
    });
  };

  return (
    <AttributesPane value={PaneIdentifier.TextCreation}>
      <AttributesPaneControls dispatch={dispatch} />
      <form className="p-2 flex flex-col gap-2" onSubmit={onSubmit}>
        <Textarea
          placeholder={t("TextCreationPane.placeholder")}
          required
          value={textAttribute}
          onChange={(event) => setTextAttribute(event.target.value)}
          name="text"
        />
        <Button type="submit">{t("TextCreationPane.labels.confirm")}</Button>
      </form>
    </AttributesPane>
  );
}
