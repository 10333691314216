import { Dispatch, useState } from "react";
import { EditorAction, PaneIdentifier, TabIdentifier } from "../../reducers";
import AttributesPane from "../AttributesPane";
import AttributesPaneControls from "../AttributesPaneControls";
import { Input } from "@acdc2/ui/components/input";
import { AccessButton } from "@acdc2/ui/components/access-button";
import {
  ListItemCreationPaneFragment,
  useCreateListItemAttributeMutation,
} from "../../../client/generated";
import { useTranslation } from "react-i18next";

type Props = {
  dispatch: Dispatch<EditorAction>;
  fragment: ListItemCreationPaneFragment;
};

export default function ListItemCreationPane({
  dispatch,
  fragment,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const [ordinal, setOrdinal] = useState<number>();

  const [createListItem] = useCreateListItemAttributeMutation();

  const onSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (!ordinal) return;

    await createListItem({
      variables: {
        regionId: fragment.id,
        ordinal: ordinal,
      },
    });

    dispatch({
      type: "switchPane",
      paneState: {
        tabIdentifier: TabIdentifier.Attributes,
        paneIdentifier: PaneIdentifier.Default,
      },
    });
  };

  return (
    <AttributesPane value={PaneIdentifier.ListItemCreation}>
      <AttributesPaneControls dispatch={dispatch} />
      <form className="p-2 flex flex-col gap-2" onSubmit={onSubmit}>
        <Input
          placeholder={t("ListItemCreationPane.placeholder")}
          type="number"
          inputMode="numeric"
          min={1}
          value={ordinal}
          onChange={(event) => setOrdinal(parseInt(event.target.value))}
          required
        />
        <AccessButton type="submit">
          {t("ListItemCreationPane.labels.add")}
        </AccessButton>
      </form>
    </AttributesPane>
  );
}
