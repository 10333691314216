import { KeyboardEvent, useCallback, useState } from "react";
import { EditorAction } from "../reducers";
import RegionContextMenu from "./RegionContextMenu";
import {
  RegionContextHandlerFragment,
  useDeleteRegionMutation,
} from "../../client/generated";

type Props = {
  fragment: RegionContextHandlerFragment;
  isSelected: boolean;
  children: React.ReactNode;
  dispatch: React.Dispatch<EditorAction>;
};

type Point = {
  x: number;
  y: number;
};

export default function RegionContextHandler({
  fragment,
  isSelected,
  children,
  dispatch,
}: Props): JSX.Element {
  const [contextMenuPosition, setContextMenuPosition] = useState<Point>();

  const [deleteRegion] = useDeleteRegionMutation({
    variables: { regionId: fragment.id },
    update(cache) {
      const id = cache.identify(fragment);
      cache.evict({ id });
      cache.gc();
    },
  });

  const onDelete = useCallback(async () => {
    const { errors, data } = await deleteRegion();

    if (errors) {
      console.error("Error deleting region:", errors);
    } else if (data?.deleteRegion.userErrors) {
      console.error(
        "User error deleting region:",
        data.deleteRegion.userErrors,
      );
    } else {
      dispatch({ type: "deselectRegion" });
    }
  }, [deleteRegion, dispatch]);

  const onContextMenu = (event: React.MouseEvent<SVGPolygonElement>) => {
    if (!isSelected) return;
    event.preventDefault();
    setContextMenuPosition({ x: event.pageX, y: event.pageY });
  };

  const onKeyDown = async (event: KeyboardEvent<SVGPolygonElement>) => {
    if (event.key === "Delete" || event.key === "Backspace") {
      onDelete();
    }
  };

  return (
    <>
      <g tabIndex={0} onKeyDown={onKeyDown} onContextMenu={onContextMenu}>
        {children}
      </g>
      {contextMenuPosition && (
        <RegionContextMenu
          position={contextMenuPosition}
          onDelete={onDelete}
          onClose={() => setContextMenuPosition(undefined)}
        />
      )}
    </>
  );
}
