import { Dispatch, useState } from "react";
import { AccessButton } from "@acdc2/ui/components/access-button";
import { useTranslation } from "react-i18next";
import { EditorAction, PaneIdentifier, TabIdentifier } from "../../reducers";
import AttributesPane from "../AttributesPane";
import AttributesPaneControls from "../AttributesPaneControls";
import {
  PaginationArtifactCreationPaneFragment,
  PaginationArtifactType,
  useCreatePaginationArtifactMutation,
} from "../../../client/generated";
import PaginationArtifactTypeSelect from "./PaginationArtifactTypeSelect";

type Props = {
  dispatch: Dispatch<EditorAction>;
  fragment: PaginationArtifactCreationPaneFragment;
};

export default function PaginationArtifactCreationPane({
  dispatch,
  fragment,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const [artifactType, setArtifactType] = useState<string>();

  const [createPaginationArtifact] = useCreatePaginationArtifactMutation();

  const onSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (!artifactType) return;

    await createPaginationArtifact({
      variables: {
        regionId: fragment.id,
        type: artifactType as PaginationArtifactType,
      },
    });

    dispatch({
      type: "switchPane",
      paneState: {
        tabIdentifier: TabIdentifier.Attributes,
        paneIdentifier: PaneIdentifier.Default,
      },
    });
  };
  return (
    <AttributesPane value={PaneIdentifier.PaginationArtifactCreation}>
      <AttributesPaneControls dispatch={dispatch} />
      <form className="p-2 flex flex-col gap-2" onSubmit={onSubmit}>
        <PaginationArtifactTypeSelect
          value={artifactType}
          onValueChange={setArtifactType}
        />
        <AccessButton type="submit">
          {t("PaginationArtifactCreationPane.labels.add")}
        </AccessButton>
      </form>
    </AttributesPane>
  );
}
