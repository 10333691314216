import { SVGProps } from "react";
import { Vertex } from "../../client/generated";
import { cn } from "@acdc2/ui/lib/utils";

type Props = SVGProps<SVGPolygonElement> & {
  vertices: Vertex[];
};

export default function RegionPolygon({
  vertices,
  fill,
  stroke,
  className,
  ...rest
}: Props) {
  return (
    <svg viewBox="0 0 100 100" preserveAspectRatio="none">
      <polygon
        points={vertices
          .map((vertex) => `${vertex.left * 100},${vertex.top * 100}`)
          .join(" ")}
        fill={fill ?? "transparent"}
        stroke={stroke ?? "pink"}
        vectorEffect="non-scaling-stroke"
        tabIndex={0}
        className={cn("outline-none", className)}
        {...rest}
      />
    </svg>
  );
}
