import { Dispatch } from "react";
import { TabIdentifier, EditorAction } from "../reducers";
import {
  AttributesListItemFragment,
  useDeleteAttributeMutation,
} from "../../client/generated";

type Props = {
  fragment: AttributesListItemFragment;
  dispatch: Dispatch<EditorAction>;
};

export default function AttributesListItem({
  fragment,
  dispatch,
}: Props): JSX.Element {
  const [deleteAttribute] = useDeleteAttributeMutation({
    update(cache) {
      const id = cache.identify(fragment);
      cache.evict({ id });
      cache.gc();
    },
  });

  const onEdit = () => {
    dispatch({
      type: "switchPane",
      paneState: {
        tabIdentifier: TabIdentifier.Attributes,
        paneIdentifier: fragment.id,
      },
    });
  };

  const onDelete = async () => {
    await deleteAttribute({
      variables: { attributeId: fragment.id },
    });
  };

  return (
    <li>
      <div>{fragment.__typename}</div>
      {fragment.__typename === "HeaderLevelAttribute" && (
        <button onClick={onEdit}>Edit</button>
      )}
      {fragment.__typename === "TextAttribute" && (
        <button onClick={onEdit}>Edit</button>
      )}
      {fragment.__typename === "AlternativeTextAttribute" && (
        <button onClick={onEdit}>Edit</button>
      )}
      {fragment.__typename === "PaginationArtifactAttribute" && (
        <button onClick={onEdit}>Edit</button>
      )}
      {fragment.__typename === "ListAttribute" && (
        <button onClick={onEdit}>Edit</button>
      )}
      {fragment.__typename === "ListItemAttribute" && (
        <button onClick={onEdit}>Edit</button>
      )}
      <span> | </span>
      <button onClick={onDelete}>Delete</button>
    </li>
  );
}
