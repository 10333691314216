import { useParams } from "react-router-dom";
import { useReducer } from "react";
import { usePageEditorScreenSuspenseQuery } from "../client/generated";
import {
  editorReducer,
  TabIdentifier,
  RegionMode,
  PaneIdentifier,
} from "../editors/reducers";
import Sidebar from "../shell/Sidebar";
import Scaffold from "../shell/Scaffold";
import MetadataSidebarTabButton from "../editors/metadata/MetadataSidebarTabButton";
import MetadataSidebarTabContent from "../editors/metadata/MetadataSidebarTabContent";
import RegionsEditorSidebarTabButton from "../editors/regions/RegionsEditorSidebarTabButton";
import ReadingOrderSidebarTabButton from "../editors/reading-order/ReadingOrderSidebarTabButton";
import RegionsEditorSidebarTabContent from "../editors/regions/RegionsEditorSidebarTabContent";
import ReadingOrderSidebarTabContent from "../editors/reading-order/ReadingOrderSidebarTabContent";
import PagesOverviewSidebarTabButton from "../editors/pages/PagesOverviewSidebarTabButton";
import PagesOverviewSidebarTabContent from "../editors/pages/PagesOverviewSidebarTabContent";
import OverviewSidebarButton from "../editors/OverviewSidebarButton";
import SidebarDivider from "../shell/SidebarDivider";
import PageEditor from "../editors/PageEditor";
import PreviousPageSidebarButton from "../editors/PreviousPageSidebarButton";
import NextPageSidebarButton from "../editors/NextPageSidebarButton";
import AttributesEditorSidebarTabContent from "../editors/attributes/AttributesEditorSidebarTabContent";
import AttributesEditorSidebarTabButton from "../editors/attributes/AttributesEditorSidebarTabButton";

export default function PageEditorScreen(): JSX.Element {
  const [state, dispatch] = useReducer(editorReducer, {
    mode: RegionMode.Rectangle,
    paneState: {
      tabIdentifier: TabIdentifier.Metadata,
      paneIdentifier: PaneIdentifier.Default,
    },
  });
  const { documentId, pageId } = useParams<{
    documentId: string;
    pageId: string;
  }>();

  const { data } = usePageEditorScreenSuspenseQuery({
    variables: { documentId: documentId!, pageId: pageId! },
  });

  if (
    data.document?.__typename !== "Document" ||
    data.page?.__typename !== "Page"
  ) {
    throw TypeError("Document or Page not found");
  }

  return (
    <Scaffold
      sidebar={
        <Sidebar
          value={state.paneState.tabIdentifier}
          onValueChange={(value) => {
            dispatch({
              type: "switchPane",
              paneState: {
                tabIdentifier: value as TabIdentifier,
                paneIdentifier: PaneIdentifier.Default,
              },
            });
          }}
          buttons={
            <>
              <OverviewSidebarButton fragment={data.document} />
              <PreviousPageSidebarButton fragment={data.page} />
              <NextPageSidebarButton fragment={data.page} />
              <SidebarDivider />
              <MetadataSidebarTabButton />
              <PagesOverviewSidebarTabButton />
              <RegionsEditorSidebarTabButton />
              <AttributesEditorSidebarTabButton />
              <ReadingOrderSidebarTabButton />
            </>
          }
          content={
            <>
              <MetadataSidebarTabContent fragment={data.document} />
              <PagesOverviewSidebarTabContent fragment={data.page} />
              <RegionsEditorSidebarTabContent
                state={state}
                dispatch={dispatch}
              />
              <AttributesEditorSidebarTabContent
                state={state}
                dispatch={dispatch}
              />
              <ReadingOrderSidebarTabContent />
            </>
          }
        />
      }
    >
      <PageEditor fragment={data.page} state={state} dispatch={dispatch} />
    </Scaffold>
  );
}
