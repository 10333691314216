import { Dispatch, useState } from "react";
import { Textarea } from "@acdc2/ui/components/textarea";
import { AccessButton } from "@acdc2/ui/components/access-button";
import { useTranslation } from "react-i18next";
import { EditorAction, PaneIdentifier, TabIdentifier } from "../../reducers";
import AttributesPaneControls from "../AttributesPaneControls";
import AttributesPane from "../AttributesPane";
import {
  TextUpdatePaneFragment,
  useUpdateTextAttributeMutation,
} from "../../../client/generated";

type Props = {
  fragment: TextUpdatePaneFragment;
  dispatch: Dispatch<EditorAction>;
};

export default function TextUpdatePane({
  fragment,
  dispatch,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const [text, setText] = useState(fragment.text);

  const [updateText, { loading }] = useUpdateTextAttributeMutation();

  const onSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    await updateText({
      variables: {
        attributeId: fragment.id,
        text,
      },
    });

    dispatch({
      type: "switchPane",
      paneState: {
        tabIdentifier: TabIdentifier.Attributes,
        paneIdentifier: PaneIdentifier.Default,
      },
    });
  };

  return (
    <AttributesPane value={fragment.id}>
      <AttributesPaneControls dispatch={dispatch} />
      <form className="p-2 flex flex-col gap-2" onSubmit={onSubmit}>
        <Textarea
          required
          value={text}
          onChange={(event) => setText(event.target.value)}
          rows={8}
        />
        <AccessButton type="submit" loading={loading}>
          {t("TextUpdatePane.labels.confirm")}
        </AccessButton>
      </form>
    </AttributesPane>
  );
}
