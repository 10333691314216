import { KeyboardEvent, Dispatch, useCallback, useState } from "react";
import {
  Aabb,
  TransformableRegionFragment,
  useUpdateRegionShapeMutation,
} from "../../client/generated";
import { EditorAction } from "../reducers";
import { usePageScale } from "../hooks";
import { toPercent, CSS_IN_TO_PX } from "../utils";
import RegionTransformHandle from "./RegionTransformHandle";
import RegionPolygon from "./RegionPolygon";
import RegionDragHandler from "./RegionDragHandler";
import RegionSelectHandler from "./RegionSelectHandler";
import RegionContextHandler from "./RegionContextHandler";

type Props = {
  fragment: TransformableRegionFragment;
  isSelected: boolean;
  dispatch: Dispatch<EditorAction>;
};

export default function TransformableRegion({
  fragment,
  isSelected,
  dispatch,
}: Props) {
  const pageScale = usePageScale();

  const [deltaAABB, setDeltaAABB] = useState<Aabb>({
    height: 0,
    left: 0,
    top: 0,
    width: 0,
  });

  const pageWidth = fragment.page.width * CSS_IN_TO_PX;
  const pageHeight = fragment.page.height * CSS_IN_TO_PX;
  const scaleX = deltaAABB.width / fragment.shape.aabb.width + 1;
  const scaleY = deltaAABB.height / fragment.shape.aabb.height + 1;
  const deltaX = deltaAABB.left * pageWidth;
  const deltaY = deltaAABB.top * pageHeight;

  const [updateRegionShape] = useUpdateRegionShapeMutation();

  const onUpdate = useCallback(
    async (delta: Aabb) => {
      function transformX(x: number): number {
        const transformedX =
          fragment.shape.aabb.left +
          delta.left +
          (x - fragment.shape.aabb.left) * scaleX;
        return transformedX;
      }

      function transformY(y: number): number {
        const transformedY =
          fragment.shape.aabb.top +
          delta.top +
          (y - fragment.shape.aabb.top) * scaleY;
        return transformedY;
      }

      const { errors, data } = await updateRegionShape({
        variables: {
          regionId: fragment.id,
          vertices: fragment.shape.vertices.map((vertex) => ({
            left: transformX(vertex.left),
            top: transformY(vertex.top),
          })),
        },
      });

      if (errors) {
        console.error("Error moving region:", errors);
      } else if (data?.updateRegionShape.userErrors) {
        console.error(
          "User error moving region:",
          data.updateRegionShape.userErrors,
        );
      } else {
        setDeltaAABB({ height: 0, left: 0, top: 0, width: 0 });
      }
    },
    [scaleX, scaleY, fragment, updateRegionShape],
  );

  const onKeyDown = async (event: KeyboardEvent<SVGPolygonElement>) => {
    if (event.key === "ArrowLeft") {
      onUpdate({
        ...deltaAABB,
        left: deltaAABB.left - 0.001,
      });
    } else if (event.key === "ArrowRight") {
      onUpdate({
        ...deltaAABB,
        left: deltaAABB.left + 0.001,
      });
    } else if (event.key === "ArrowUp") {
      onUpdate({
        ...deltaAABB,
        top: deltaAABB.top - 0.001,
      });
    } else if (event.key === "ArrowDown") {
      onUpdate({
        ...deltaAABB,
        top: deltaAABB.top + 0.001,
      });
    }
  };

  return (
    <>
      <g
        transform={`translate(${deltaX}, ${deltaY}) scale(${scaleX}, ${scaleY})`}
        style={{ transformBox: "fill-box" }}
      >
        <RegionDragHandler
          onDone={() => onUpdate(deltaAABB)}
          onDrag={(movementX, movementY) => {
            setDeltaAABB((oldDeltaAABB) => ({
              ...oldDeltaAABB,
              top: oldDeltaAABB.top + movementY / pageHeight / pageScale,
              left: oldDeltaAABB.left + movementX / pageWidth / pageScale,
            }));
          }}
        >
          <RegionSelectHandler
            fragment={fragment}
            isSelected={isSelected}
            dispatch={dispatch}
          >
            <RegionContextHandler
              fragment={fragment}
              isSelected={isSelected}
              dispatch={dispatch}
            >
              <RegionPolygon
                vertices={fragment.shape.vertices}
                onKeyDown={onKeyDown}
              />
            </RegionContextHandler>
          </RegionSelectHandler>
        </RegionDragHandler>
      </g>

      {isSelected && (
        <>
          <rect
            x={toPercent(
              Math.min(
                fragment.shape.aabb.left + deltaAABB.left,
                fragment.shape.aabb.left +
                  deltaAABB.left +
                  fragment.shape.aabb.width +
                  deltaAABB.width,
              ),
            )}
            y={toPercent(
              Math.min(
                fragment.shape.aabb.top + deltaAABB.top,
                fragment.shape.aabb.top +
                  deltaAABB.top +
                  fragment.shape.aabb.height +
                  deltaAABB.height,
              ),
            )}
            width={toPercent(
              Math.abs(fragment.shape.aabb.width + deltaAABB.width),
            )}
            height={toPercent(
              Math.abs(fragment.shape.aabb.height + deltaAABB.height),
            )}
            fill="transparent"
            stroke="hsl(var(--brand))"
            strokeWidth={1}
            className="pointer-events-none"
          />

          <RegionTransformHandle
            direction="secondary"
            cx={(fragment.shape.aabb.left + deltaAABB.left) * pageWidth}
            cy={(fragment.shape.aabb.top + deltaAABB.top) * pageHeight}
            onDrag={(movementX, movementY) => {
              setDeltaAABB((oldDeltaAABB) => ({
                top: oldDeltaAABB.top + movementY / pageHeight / pageScale,
                left: oldDeltaAABB.left + movementX / pageWidth / pageScale,
                width: oldDeltaAABB.width - movementX / pageWidth / pageScale,
                height:
                  oldDeltaAABB.height - movementY / pageHeight / pageScale,
              }));
            }}
            onDone={() => onUpdate(deltaAABB)}
          />
          <RegionTransformHandle
            direction="vertical"
            cx={
              (fragment.shape.aabb.left +
                deltaAABB.left +
                (fragment.shape.aabb.width + deltaAABB.width) / 2) *
              pageWidth
            }
            cy={(fragment.shape.aabb.top + deltaAABB.top) * pageHeight}
            onDrag={(_, movementY) => {
              setDeltaAABB((oldDeltaAABB) => ({
                ...oldDeltaAABB,
                top: oldDeltaAABB.top + movementY / pageHeight / pageScale,
                height:
                  oldDeltaAABB.height - movementY / pageHeight / pageScale,
              }));
            }}
            onDone={() => onUpdate(deltaAABB)}
          />
          <RegionTransformHandle
            direction="primary"
            cx={
              (fragment.shape.aabb.left +
                deltaAABB.left +
                fragment.shape.aabb.width +
                deltaAABB.width) *
              pageWidth
            }
            cy={(fragment.shape.aabb.top + deltaAABB.top) * pageHeight}
            onDrag={(movementX, movementY) => {
              setDeltaAABB((oldDeltaAABB) => ({
                ...oldDeltaAABB,
                top: oldDeltaAABB.top + movementY / pageHeight / pageScale,
                width: oldDeltaAABB.width + movementX / pageWidth / pageScale,
                height:
                  oldDeltaAABB.height - movementY / pageHeight / pageScale,
              }));
            }}
            onDone={() => onUpdate(deltaAABB)}
          />

          <RegionTransformHandle
            direction="horizontal"
            cx={(fragment.shape.aabb.left + deltaAABB.left) * pageWidth}
            cy={
              (fragment.shape.aabb.top +
                deltaAABB.top +
                (fragment.shape.aabb.height + deltaAABB.height) / 2) *
              pageHeight
            }
            onDrag={(movementX) => {
              setDeltaAABB((oldDeltaAABB) => ({
                ...oldDeltaAABB,
                left: oldDeltaAABB.left + movementX / pageWidth / pageScale,
                width: oldDeltaAABB.width - movementX / pageWidth / pageScale,
              }));
            }}
            onDone={() => onUpdate(deltaAABB)}
          />
          <RegionTransformHandle
            direction="horizontal"
            cx={
              (fragment.shape.aabb.left +
                deltaAABB.left +
                fragment.shape.aabb.width +
                deltaAABB.width) *
              pageWidth
            }
            cy={
              (fragment.shape.aabb.top +
                deltaAABB.top +
                (fragment.shape.aabb.height + deltaAABB.height) / 2) *
              pageHeight
            }
            onDrag={(movementX) => {
              setDeltaAABB((oldDeltaAABB) => ({
                ...oldDeltaAABB,
                width: oldDeltaAABB.width + movementX / pageWidth / pageScale,
              }));
            }}
            onDone={() => onUpdate(deltaAABB)}
          />

          <RegionTransformHandle
            direction="primary"
            cx={(fragment.shape.aabb.left + deltaAABB.left) * pageWidth}
            cy={
              (fragment.shape.aabb.top +
                deltaAABB.top +
                fragment.shape.aabb.height +
                deltaAABB.height) *
              pageHeight
            }
            onDrag={(movementX, movementY) => {
              setDeltaAABB((oldDeltaAABB) => ({
                ...oldDeltaAABB,
                left: oldDeltaAABB.left + movementX / pageWidth / pageScale,
                width: oldDeltaAABB.width - movementX / pageWidth / pageScale,
                height:
                  oldDeltaAABB.height + movementY / pageHeight / pageScale,
              }));
            }}
            onDone={() => onUpdate(deltaAABB)}
          />
          <RegionTransformHandle
            direction="vertical"
            cx={
              (fragment.shape.aabb.left +
                deltaAABB.left +
                (fragment.shape.aabb.width + deltaAABB.width) / 2) *
              pageWidth
            }
            cy={
              (fragment.shape.aabb.top +
                deltaAABB.top +
                fragment.shape.aabb.height +
                deltaAABB.height) *
              pageHeight
            }
            onDrag={(_, movementY) => {
              setDeltaAABB((oldDeltaAABB) => ({
                ...oldDeltaAABB,
                height:
                  oldDeltaAABB.height + movementY / pageHeight / pageScale,
              }));
            }}
            onDone={() => onUpdate(deltaAABB)}
          />
          <RegionTransformHandle
            direction="secondary"
            cx={
              (fragment.shape.aabb.left +
                deltaAABB.left +
                fragment.shape.aabb.width +
                deltaAABB.width) *
              pageWidth
            }
            cy={
              (fragment.shape.aabb.top +
                deltaAABB.top +
                fragment.shape.aabb.height +
                deltaAABB.height) *
              pageHeight
            }
            onDrag={(movementX, movementY) => {
              setDeltaAABB((oldDeltaAABB) => ({
                ...oldDeltaAABB,
                width: oldDeltaAABB.width + movementX / pageWidth / pageScale,
                height:
                  oldDeltaAABB.height + movementY / pageHeight / pageScale,
              }));
            }}
            onDone={() => onUpdate(deltaAABB)}
          />
        </>
      )}
    </>
  );
}
