import { Dispatch, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "@acdc2/ui/components/button";
import { Icon } from "@mdi/react";
import { mdiTagPlus } from "@mdi/js";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "@acdc2/ui/components/select";
import { PaneIdentifier, EditorAction, TabIdentifier } from "../reducers";

type Props = {
  dispatch: Dispatch<EditorAction>;
};

export default function AttributesToolbox({ dispatch }: Props): JSX.Element {
  const { t } = useTranslation();
  const [attributeSelection, setAttributeSelection] = useState<
    PaneIdentifier | ""
  >("");

  const onValueChange = async (value: PaneIdentifier) => {
    setAttributeSelection(value);
  };

  const onClick = async () => {
    if (attributeSelection) {
      dispatch({
        type: "switchPane",
        paneState: {
          tabIdentifier: TabIdentifier.Attributes,
          paneIdentifier: attributeSelection,
        },
      });
    }
  };

  return (
    <div className="flex flex-row gap-2">
      <Select onValueChange={onValueChange} value={attributeSelection}>
        <SelectTrigger>
          <SelectValue placeholder={t("AttributesToolbox.placeholder")} />
        </SelectTrigger>
        <SelectContent>
          <SelectGroup>
            <SelectLabel>
              {t("AttributesToolbox.groups.text.title")}
            </SelectLabel>
            <SelectItem value={PaneIdentifier.HeadingLevelCreation}>
              {t("AttributesToolbox.groups.text.labels.headingLevel")}
            </SelectItem>
            <SelectItem value={PaneIdentifier.TextCreation}>
              {t("AttributesToolbox.groups.text.labels.text")}
            </SelectItem>
            <SelectItem value={PaneIdentifier.ExpressionCreation}>
              {t("AttributesToolbox.groups.text.labels.expression")}
            </SelectItem>
          </SelectGroup>
          <SelectGroup>
            <SelectLabel>
              {t("AttributesToolbox.groups.lists.title")}
            </SelectLabel>
            <SelectItem value={PaneIdentifier.ListCreation}>
              {t("AttributesToolbox.groups.lists.labels.list")}
            </SelectItem>
            <SelectItem value={PaneIdentifier.ListItemCreation}>
              {t("AttributesToolbox.groups.lists.labels.listItem")}
            </SelectItem>
          </SelectGroup>
          <SelectGroup>
            <SelectLabel>
              {t("AttributesToolbox.groups.media.title")}
            </SelectLabel>
            <SelectItem value={PaneIdentifier.ImageCreation}>
              {t("AttributesToolbox.groups.media.labels.image")}
            </SelectItem>
            <SelectItem value={PaneIdentifier.FigureCreation}>
              {t("AttributesToolbox.groups.media.labels.figure")}
            </SelectItem>
            <SelectItem value={PaneIdentifier.CaptionCreation}>
              {t("AttributesToolbox.groups.media.labels.caption")}
            </SelectItem>
            <SelectItem value={PaneIdentifier.AlternativeTextCreation}>
              {t("AttributesToolbox.groups.media.labels.alternativeText")}
            </SelectItem>
          </SelectGroup>
          <SelectGroup>
            <SelectLabel>
              {t("AttributesToolbox.groups.tables.title")}
            </SelectLabel>
            <SelectItem value={PaneIdentifier.TableCreation}>
              {t("AttributesToolbox.groups.tables.labels.table")}
            </SelectItem>
            <SelectItem value={PaneIdentifier.TableCellCreation}>
              {t("AttributesToolbox.groups.tables.labels.tableCell")}
            </SelectItem>
            <SelectItem value={PaneIdentifier.TableHeaderCellCreation}>
              {t("AttributesToolbox.groups.tables.labels.tableHeaderCell")}
            </SelectItem>
          </SelectGroup>
          <SelectGroup>
            <SelectLabel>
              {t("AttributesToolbox.groups.decorative.title")}
            </SelectLabel>
            <SelectItem value={PaneIdentifier.ExcludeReadingOrderCreation}>
              {t(
                "AttributesToolbox.groups.decorative.labels.excludeReadingOrder",
              )}
            </SelectItem>
            <SelectItem value={PaneIdentifier.PaginationArtifactCreation}>
              {t(
                "AttributesToolbox.groups.decorative.labels.paginationArtifact",
              )}
            </SelectItem>
          </SelectGroup>
          <SelectGroup>
            <SelectLabel>{t("AttributesToolbox.groups.toc.title")}</SelectLabel>
            <SelectItem value={PaneIdentifier.TableOfContentsCreation}>
              {t("AttributesToolbox.groups.toc.labels.tableOfContents")}
            </SelectItem>
            <SelectItem value={PaneIdentifier.TableOfContentsItemCreation}>
              {t("AttributesToolbox.groups.toc.labels.tableOfContentsItem")}
            </SelectItem>
          </SelectGroup>
        </SelectContent>
      </Select>
      <div>
        <Button
          variant="outline"
          size="icon"
          onClick={onClick}
          disabled={!attributeSelection}
        >
          <Icon path={mdiTagPlus} size={1} />
        </Button>
      </div>
    </div>
  );
}
