import {
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@acdc2/ui/components/accordion";
import { Button } from "@acdc2/ui/components/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@acdc2/ui/components/dialog";
import { Label } from "@acdc2/ui/components/label";
import { useTranslation } from "react-i18next";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@acdc2/ui/components/popover";
import { Calendar } from "@acdc2/ui/components/calendar";
import dayjs from "dayjs";
import { MetadataDateAccordionItemFragment } from "../../client/generated";
import { useState } from "react";

type Props = {
  fragment: MetadataDateAccordionItemFragment;
};

export default function MetadataKeywordAccordionItem({
  fragment,
}: Props): JSX.Element {
  const { t, i18n } = useTranslation();
  const [date, setDate] = useState(
    fragment.metadata.date ? new Date(fragment.metadata.date) : undefined,
  );

  return (
    <AccordionItem value="date">
      <AccordionTrigger className="mx-2">
        {t("MetadataDateAccordionItem.label")}
      </AccordionTrigger>
      <AccordionContent className="mx-2">
        <Dialog>
          <DialogTrigger asChild>
            <Button variant="ghost" className="w-full justify-start">
              {date ? (
                <span>
                  {dayjs(date).locale(i18n.language).format("DD. MMMM YYYY")}
                </span>
              ) : (
                <span>{t("MetadataDateAccordionItem.placeholder")}</span>
              )}
            </Button>
          </DialogTrigger>
          <DialogContent>
            <DialogHeader>
              <DialogTitle>
                {t("MetadataDateAccordionItem.dialog.title")}
              </DialogTitle>
              <DialogDescription>
                {t("MetadataDateAccordionItem.dialog.description")}
              </DialogDescription>
            </DialogHeader>
            <Label htmlFor="date" className="flex flex-col gap-2">
              {t("MetadataDateAccordionItem.dialog.dateInput.label")}
              <Popover>
                <PopoverTrigger asChild>
                  <Button variant="outline">
                    {date ? (
                      <span>
                        {dayjs(date)
                          .locale(i18n.language)
                          .format("DD. MMMM YYYY")}
                      </span>
                    ) : (
                      <span>
                        {t(
                          "MetadataDateAccordionItem.dialog.dateInput.placeholder",
                        )}
                      </span>
                    )}
                  </Button>
                </PopoverTrigger>
                <PopoverContent className="w-auto p-0">
                  <Calendar
                    mode="single"
                    selected={date}
                    onDayClick={setDate}
                    initialFocus
                  />
                </PopoverContent>
              </Popover>
            </Label>
            <DialogFooter>
              <Button type="submit">
                {t("MetadataDateAccordionItem.dialog.button")}
              </Button>
            </DialogFooter>
          </DialogContent>
        </Dialog>
      </AccordionContent>
    </AccordionItem>
  );
}
