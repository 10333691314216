import { Dispatch, useState } from "react";
import {
  ReshapeableRegionFragment,
  useUpdateRegionVerticesMutation,
  Vertex,
} from "../../client/generated";
import { EditorAction } from "../reducers";
import { CSS_IN_TO_PX } from "../utils";
import { usePageScale } from "../hooks";
import RegionReshapeHandle from "./RegionReshapeHandle";
import RegionPolygon from "./RegionPolygon";
import RegionSelectHandler from "./RegionSelectHandler";
import RegionDragHandler from "./RegionDragHandler";
import VertexInsertionHandle from "./VertexInsertionHandle";

type Props = {
  fragment: ReshapeableRegionFragment;
  isSelected: boolean;
  dispatch: Dispatch<EditorAction>;
};

export default function ReshapeableRegion({
  fragment,
  isSelected,
  dispatch,
}: Props) {
  const pageScale = usePageScale();
  const pageWidth = fragment.page.width * CSS_IN_TO_PX;
  const pageHeight = fragment.page.height * CSS_IN_TO_PX;
  const [vertices, setVertices] = useState<Vertex[]>(fragment.shape.vertices);
  const [activeVertexIndex, setActiveVertexIndex] = useState<number>();

  const overlappedVertexIndex = getOverlappedNeighbourIndex(
    vertices,
    activeVertexIndex,
    pageWidth,
    pageHeight,
  );

  const vertexInsertionPoints = isSelected
    ? getVertexInsertionPoints(vertices)
    : [];

  const [mutate] = useUpdateRegionVerticesMutation();

  const onDone = async () => {
    const nonOverlappingVertices = [...vertices];

    if (
      activeVertexIndex !== undefined &&
      overlappedVertexIndex !== undefined
    ) {
      nonOverlappingVertices.splice(activeVertexIndex, 1);
    }

    const { data, errors } = await mutate({
      variables: {
        regionId: fragment.id,
        vertices: nonOverlappingVertices.map((vertex) => ({
          left: vertex.left,
          top: vertex.top,
        })),
      },
    });

    if (errors) {
      console.error("Error updating region vertices:", errors);
    } else if (data?.updateRegionShape.userErrors) {
      console.error(
        "User error updating region vertices:",
        data.updateRegionShape.userErrors,
      );
    }

    setActiveVertexIndex(undefined);
    setVertices(
      data?.updateRegionShape.region?.shape.vertices ?? fragment.shape.vertices,
    );
  };

  return (
    <>
      <RegionDragHandler
        onDrag={(movementX, movementY) => {
          setVertices((oldVertices) => {
            return oldVertices.map((vertex) => ({
              left: vertex.left + movementX / pageWidth / pageScale,
              top: vertex.top + movementY / pageHeight / pageScale,
            }));
          });
        }}
        onDone={onDone}
      >
        <RegionSelectHandler
          fragment={fragment}
          isSelected={isSelected}
          dispatch={dispatch}
        >
          <RegionPolygon
            vertices={vertices}
            stroke={isSelected ? "hsl(var(--brand))" : "pink"}
          />
        </RegionSelectHandler>
      </RegionDragHandler>

      {isSelected &&
        vertices.map((vertex, index) => (
          <RegionReshapeHandle
            key={index}
            cx={vertex.left * pageWidth}
            cy={vertex.top * pageHeight}
            onMove={(dx, dy) => {
              setVertices((oldVertices) => {
                const newVertices = [...oldVertices];
                newVertices[index] = {
                  left: oldVertices[index].left + dx / pageWidth / pageScale,
                  top: oldVertices[index].top + dy / pageHeight / pageScale,
                };
                return newVertices;
              });
            }}
            onDone={onDone}
            onSelect={() => setActiveVertexIndex(index)}
            isActive={index === activeVertexIndex}
            isOverlapping={index === overlappedVertexIndex}
          />
        ))}

      {vertexInsertionPoints.map((midpoint, index) => (
        <VertexInsertionHandle
          key={index}
          cx={midpoint.left * pageWidth}
          cy={midpoint.top * pageHeight}
          onInsert={() => {
            setVertices((oldVertices) => {
              const newVertices = [...oldVertices];
              newVertices.splice(index + 1, 0, midpoint);
              return newVertices;
            });
          }}
        />
      ))}
    </>
  );
}

const MERGE_THRESHOLD = 4;

function getOverlappedNeighbourIndex(
  vertices: Vertex[],
  index: number | undefined,
  pageWidth: number,
  pageHeight: number,
) {
  if (index === undefined) return undefined;
  const vertex = vertices[index];

  const prevIndex = (index - 1 + vertices.length) % vertices.length;
  const prevVertex = vertices[prevIndex];

  if (
    Math.abs(vertex.left - prevVertex.left) * pageWidth < MERGE_THRESHOLD &&
    Math.abs(vertex.top - prevVertex.top) * pageHeight < MERGE_THRESHOLD
  ) {
    return prevIndex;
  }

  const nextIndex = (index + 1) % vertices.length;
  const nextVertex = vertices[nextIndex];

  if (
    Math.abs(vertex.left - nextVertex.left) * pageWidth < MERGE_THRESHOLD &&
    Math.abs(vertex.top - nextVertex.top) * pageHeight < MERGE_THRESHOLD
  ) {
    return nextIndex;
  }

  return undefined;
}

function getVertexInsertionPoints(vertices: Vertex[]) {
  return vertices.map((vertex, index) => {
    const nextIndex = (index + 1) % vertices.length;
    const nextVertex = vertices[nextIndex];
    return {
      left: (vertex.left + nextVertex.left) / 2,
      top: (vertex.top + nextVertex.top) / 2,
    };
  });
}
