import { mdiSwapHorizontalVariant } from "@mdi/js";
import { useTranslation } from "react-i18next";
import SidebarTabButton from "../../shell/SidebarTabButton";
import { TabIdentifier } from "../reducers";

type Props = {
  disabled?: boolean;
};

export default function ReadingOrderSidebarTabButton({
  disabled,
}: Props): JSX.Element {
  const { t } = useTranslation();

  return (
    <SidebarTabButton
      tooltip={t("ReadingOrderSidebarTabButton.tooltip")}
      value={TabIdentifier.ReadingOrder}
      iconPath={mdiSwapHorizontalVariant}
      disabled={disabled}
    />
  );
}
