import { Dispatch } from "react";
import SidebarDivider from "../../shell/SidebarDivider";
import { EditorAction, PaneIdentifier } from "../reducers";
import { AttributesListPaneFragment } from "../../client/generated";
import AttributesToolbox from "./AttributesToolbox";
import AttributesPane from "./AttributesPane";
import AttributesList from "./AttributesList";

type Props = {
  dispatch: Dispatch<EditorAction>;
  fragment: AttributesListPaneFragment;
};

export default function AttributesListTabContent({
  dispatch,
  fragment,
}: Props): JSX.Element {
  return (
    <AttributesPane value={PaneIdentifier.Default}>
      <div className="p-2">
        <AttributesToolbox dispatch={dispatch} />
      </div>
      <SidebarDivider />
      <AttributesList fragment={fragment} dispatch={dispatch} />
    </AttributesPane>
  );
}
