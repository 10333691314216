import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@acdc2/ui/components/select";
import { useTranslation } from "react-i18next";
import { ListType } from "../../../client/generated";

type Props = React.ComponentProps<typeof Select>;

export default function ListTypeSelect(props: Props): JSX.Element {
  const { t } = useTranslation();

  return (
    <Select {...props}>
      <SelectTrigger>
        <SelectValue placeholder={t("ListSelect.placeholder")} />
      </SelectTrigger>
      <SelectContent>
        <SelectItem value={ListType.None}>
          {t("ListSelect.labels.none")}
        </SelectItem>
        <SelectItem value={ListType.Unordered}>
          {t("ListSelect.labels.unordered")}
        </SelectItem>
        <SelectItem value={ListType.Description}>
          {t("ListSelect.labels.description")}
        </SelectItem>
        <SelectItem value={ListType.Disc}>
          {t("ListSelect.labels.disc")}
        </SelectItem>
        <SelectItem value={ListType.Circle}>
          {t("ListSelect.labels.circle")}
        </SelectItem>
        <SelectItem value={ListType.Square}>
          {t("ListSelect.labels.square")}
        </SelectItem>
        <SelectItem value={ListType.Ordered}>
          {t("ListSelect.labels.ordered")}
        </SelectItem>
        <SelectItem value={ListType.Decimal}>
          {t("ListSelect.labels.decimal")}
        </SelectItem>
        <SelectItem value={ListType.UpperRoman}>
          {t("ListSelect.labels.roman.upper")}
        </SelectItem>
        <SelectItem value={ListType.LowerRoman}>
          {t("ListSelect.labels.roman.lower")}
        </SelectItem>
        <SelectItem value={ListType.UpperAlpha}>
          {t("ListSelect.labels.alpha.upper")}
        </SelectItem>
        <SelectItem value={ListType.LowerAlpha}>
          {t("ListSelect.labels.alpha.lower")}
        </SelectItem>
      </SelectContent>
    </Select>
  );
}
