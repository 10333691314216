import { mdiFileDocumentOutline } from "@mdi/js";
import { useTranslation } from "react-i18next";
import SidebarTabButton from "../../shell/SidebarTabButton";
import { TabIdentifier } from "../reducers";

export default function MetadataSidebarTabButton(): JSX.Element {
  const { t } = useTranslation();

  return (
    <SidebarTabButton
      tooltip={t("MetadataSidebarTabButton.tooltip")}
      value={TabIdentifier.Metadata}
      iconPath={mdiFileDocumentOutline}
    />
  );
}
