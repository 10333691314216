import * as Tabs from "@radix-ui/react-tabs";
import { Dispatch } from "react";
import { useTranslation } from "react-i18next";
import { skipToken, useSuspenseQuery } from "@apollo/client";
import { EditorAction, EditorState, TabIdentifier } from "../reducers";
import SidebarTabContent from "../../shell/SidebarTabContent";
import SidebarMissingSelection from "../../shell/SidebarMissingSelection";
import {
  AttributesEditorSidebarTabContentDocument,
  AttributesEditorSidebarTabContentQuery,
  AttributesEditorSidebarTabContentQueryVariables,
} from "../../client/generated";
import AttributesListPane from "./AttributesListPane";
import HeadingLevelCreationPane from "./heading-level/HeadingLevelCreationPane";
import HeadingLevelUpdatePane from "./heading-level/HeadingLevelUpdatePane";
import TextCreationPane from "./text/TextCreationPane";
import TextUpdatePane from "./text/TextUpdatePane";
import AlternativeTextCreationPane from "./alternative-text/AlternativeTextCreationPane";
import CaptionCreationPane from "./caption/CaptionCreationPane";
import ExcludeReadingOrderCreationPane from "./exclude-reading-order/ExcludeReadingOrderCreationPane";
import ExpressionCreationPane from "./expression/ExpressionCreationPane";
import FigureCreationPane from "./figure/FigureCreationPane";
import ImageCreationPane from "./image/ImageCreationPane";
import ListCreationPane from "./list/ListCreationPane";
import ListItemCreationPane from "./list-item/ListItemCreationPane";
import PaginationArtifactCreationPane from "./pagination-artifact/PaginationArtifactCreationPane";
import TableCreationPane from "./table/TableCreationPane";
import TableCellCreationPane from "./table-cell/TableCellCreationPane";
import TableHeaderCellCreationPane from "./table-header-cell/TableHeaderCellCreationPane";
import TableOfContentsCreationPane from "./table-of-contents/TableOfContentsCreationPane";
import TableOfContentsItemCreationPane from "./table-of-contents-item/TableOfContentsItemCreationPane";
import TableHeaderCellUpdatePane from "./table-header-cell/TableHeaderCellUpdatePane";
import TableCellUpdatePane from "./table-cell/TableCellUpdatePane";
import TableUpdatePane from "./table/TableUpdatePane";
import ListItemUpdatePane from "./list-item/ListItemUpdatePane";
import ListUpdatePane from "./list/ListUpdatePane";
import ExpressionUpdatePane from "./expression/ExpressionUpdatePane";
import CaptionUpdatePane from "./caption/CaptionUpdatePane";
import AlternativeTextUpdatePane from "./alternative-text/AlternativeTextUpdatePane";
import PaginationArtifactUpdatePane from "./pagination-artifact/PaginationArtifactUpdatePane";

type Props = {
  state: EditorState;
  dispatch: Dispatch<EditorAction>;
};

export default function AttributesEditorSidebarTabContent({
  state,
  dispatch,
}: Props): JSX.Element {
  const { t } = useTranslation();

  return (
    <SidebarTabContent
      value={TabIdentifier.Attributes}
      title={t("AttributesEditorSidebarTabContent.title")}
    >
      <AttributesEditorSidebarTabContentBody
        state={state}
        dispatch={dispatch}
      />
    </SidebarTabContent>
  );
}

type BodyProps = {
  state: EditorState;
  dispatch: Dispatch<EditorAction>;
};

function AttributesEditorSidebarTabContentBody({
  state,
  dispatch,
}: BodyProps): JSX.Element {
  const { data } = useSuspenseQuery<
    AttributesEditorSidebarTabContentQuery,
    AttributesEditorSidebarTabContentQueryVariables
  >(
    AttributesEditorSidebarTabContentDocument,
    state.selectedRegionId
      ? { variables: { selectedRegionId: state.selectedRegionId } }
      : skipToken,
  );

  if (data?.selectedRegion?.__typename !== "Region") {
    return <SidebarMissingSelection />;
  }

  return (
    <Tabs.Root value={state.paneState.paneIdentifier} className="flex-1">
      <AttributesListPane dispatch={dispatch} fragment={data.selectedRegion} />

      <AlternativeTextCreationPane
        dispatch={dispatch}
        fragment={data.selectedRegion}
      />
      <CaptionCreationPane dispatch={dispatch} />
      <ExcludeReadingOrderCreationPane
        dispatch={dispatch}
        fragment={data.selectedRegion}
      />
      <ExpressionCreationPane dispatch={dispatch} />
      <FigureCreationPane dispatch={dispatch} fragment={data.selectedRegion} />
      <HeadingLevelCreationPane
        dispatch={dispatch}
        fragment={data.selectedRegion}
      />
      <ImageCreationPane dispatch={dispatch} fragment={data.selectedRegion} />
      <ListCreationPane dispatch={dispatch} fragment={data.selectedRegion} />
      <ListItemCreationPane
        dispatch={dispatch}
        fragment={data.selectedRegion}
      />
      <PaginationArtifactCreationPane
        dispatch={dispatch}
        fragment={data.selectedRegion}
      />
      <TableCreationPane dispatch={dispatch} />
      <TableCellCreationPane dispatch={dispatch} />
      <TableHeaderCellCreationPane dispatch={dispatch} />
      <TableOfContentsCreationPane
        dispatch={dispatch}
        fragment={data.selectedRegion}
      />
      <TableOfContentsItemCreationPane dispatch={dispatch} />
      <TextCreationPane dispatch={dispatch} fragment={data.selectedRegion} />

      {data.selectedRegion.attributes.map((attribute) =>
        attribute.__typename === "AlternativeTextAttribute" ? (
          <AlternativeTextUpdatePane fragment={attribute} dispatch={dispatch} />
        ) : attribute.__typename === "CaptionAttribute" ? (
          <CaptionUpdatePane fragment={attribute} dispatch={dispatch} />
        ) : attribute.__typename === "ExpressionAttribute" ? (
          <ExpressionUpdatePane fragment={attribute} dispatch={dispatch} />
        ) : attribute.__typename === "HeaderLevelAttribute" ? (
          <HeadingLevelUpdatePane fragment={attribute} dispatch={dispatch} />
        ) : attribute.__typename === "ListAttribute" ? (
          <ListUpdatePane fragment={attribute} dispatch={dispatch} />
        ) : attribute.__typename === "ListItemAttribute" ? (
          <ListItemUpdatePane fragment={attribute} dispatch={dispatch} />
        ) : attribute.__typename === "PaginationArtifactAttribute" ? (
          <PaginationArtifactUpdatePane
            fragment={attribute}
            dispatch={dispatch}
          />
        ) : attribute.__typename === "TableAttribute" ? (
          <TableUpdatePane fragment={attribute} dispatch={dispatch} />
        ) : attribute.__typename === "TableCellAttribute" ? (
          <TableCellUpdatePane fragment={attribute} dispatch={dispatch} />
        ) : attribute.__typename === "TableHeaderCellAttribute" ? (
          <TableHeaderCellUpdatePane fragment={attribute} dispatch={dispatch} />
        ) : attribute.__typename === "TextAttribute" ? (
          <TextUpdatePane fragment={attribute} dispatch={dispatch} />
        ) : null,
      )}
    </Tabs.Root>
  );
}
