import { Dispatch } from "react";
import { AccessButton } from "@acdc2/ui/components/access-button";
import { useTranslation } from "react-i18next";
import { EditorAction, PaneIdentifier, TabIdentifier } from "../../reducers";
import AttributesPane from "../AttributesPane";
import AttributesPaneControls from "../AttributesPaneControls";
import {
  TableOfContentsCreationPaneFragment,
  useCreateTableOfContentsAttributeMutation,
} from "../../../client/generated";

type Props = {
  dispatch: Dispatch<EditorAction>;
  fragment: TableOfContentsCreationPaneFragment;
};

export default function TableOfContentsCreationPane({
  dispatch,
  fragment,
}: Props): JSX.Element {
  const { t } = useTranslation();

  const [createTableOfContentsAttribute] =
    useCreateTableOfContentsAttributeMutation();

  const onSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    await createTableOfContentsAttribute({
      variables: { regionId: fragment.id },
    });

    dispatch({
      type: "switchPane",
      paneState: {
        tabIdentifier: TabIdentifier.Attributes,
        paneIdentifier: PaneIdentifier.Default,
      },
    });
  };

  return (
    <AttributesPane value={PaneIdentifier.TableOfContentsCreation}>
      <AttributesPaneControls dispatch={dispatch} />
      <form onSubmit={onSubmit} className="p-2 flex flex-col">
        <AccessButton type="submit">
          {t("TableOfContentsCreationPane.labels.add")}
        </AccessButton>
      </form>
    </AttributesPane>
  );
}
