import { useTranslation } from "react-i18next";
import { TabIdentifier } from "../reducers";
import SidebarTabContent from "../../shell/SidebarTabContent";

export default function ReadingOrderSidebarTabContent(): JSX.Element {
  const { t } = useTranslation();

  return (
    <SidebarTabContent
      value={TabIdentifier.ReadingOrder}
      title={t("ReadingOrderSidebarTabContent.title")}
    >
      Reading Order Editor Content
    </SidebarTabContent>
  );
}
