import { Input } from "@acdc2/ui/components/input";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { PagesOverviewNavigationInputFragment } from "../../client/generated";
import { useNavigateTransition } from "../../navigation/useNavigateTransition";
import { routingPaths } from "../../navigation/constants";

type Props = {
  fragment: PagesOverviewNavigationInputFragment;
};

export default function PagesOverviewNavigationInput({
  fragment,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const [value, setValue] = useState(fragment.index.toString());
  const navigate = useNavigateTransition()[1];

  const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key !== "Enter") return;

    const targetPageNumber = parseInt(event.currentTarget.value);
    const targetPage = fragment.document.pages.pages.find(
      (page) => page.index === targetPageNumber,
    );

    if (!targetPage) return;
    navigate(routingPaths.documentPage(fragment.document.id, targetPage.id));
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  const onBlur = () => {
    setValue(fragment.index.toString());
  };

  return (
    <div className="flex p-2 gap-2 justify-center items-center">
      <div>
        <Input
          type="number"
          inputMode="numeric"
          min={1}
          max={fragment.document.pages.total}
          className="[appearance:textfield] text-center"
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          onKeyDown={onKeyDown}
          size={fragment.document.pages.total.toString().length}
        />
      </div>
      <span>{t("PagesOverviewNavigationInput.label")}</span>
      <div>
        <Input
          disabled
          defaultValue={fragment.document.pages.total}
          className="text-center"
          size={fragment.document.pages.total.toString().length}
        />
      </div>
    </div>
  );
}
