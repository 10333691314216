import { Dispatch } from "react";
import { EditorAction, PaneIdentifier } from "../../reducers";
import AttributesPane from "../AttributesPane";
import AttributesPaneControls from "../AttributesPaneControls";

type Props = {
  dispatch: Dispatch<EditorAction>;
};

export default function TableCreationPane({ dispatch }: Props): JSX.Element {
  return (
    <AttributesPane value={PaneIdentifier.TableCreation}>
      <AttributesPaneControls dispatch={dispatch} />
      Table Creation Pane
    </AttributesPane>
  );
}
