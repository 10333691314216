import { Dispatch } from "react";
import { EditorAction, PaneIdentifier } from "../../reducers";
import AttributesPane from "../AttributesPane";
import AttributesPaneControls from "../AttributesPaneControls";

type Props = {
  dispatch: Dispatch<EditorAction>;
};

export default function TableCellCreationPane({
  dispatch,
}: Props): JSX.Element {
  return (
    <AttributesPane value={PaneIdentifier.TableCellCreation}>
      <AttributesPaneControls dispatch={dispatch} />
      Table Cell Creation Pane
    </AttributesPane>
  );
}
