import { Dispatch } from "react";
import AttributesPane from "../AttributesPane";
import AttributesPaneControls from "../AttributesPaneControls";
import { EditorAction } from "../../reducers";
import { ExpressionUpdatePaneFragment } from "../../../client/generated";

type Props = {
  fragment: ExpressionUpdatePaneFragment;
  dispatch: Dispatch<EditorAction>;
};

export default function ExpressionUpdatePane({
  fragment,
  dispatch,
}: Props): JSX.Element {
  return (
    <AttributesPane value={fragment.id}>
      <AttributesPaneControls dispatch={dispatch} />
      Expression Update Pane for {fragment.id}
    </AttributesPane>
  );
}
