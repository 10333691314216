import { Dispatch } from "react";
import { EditorAction, PaneIdentifier } from "../../reducers";
import AttributesPane from "../AttributesPane";
import AttributesPaneControls from "../AttributesPaneControls";

type Props = {
  dispatch: Dispatch<EditorAction>;
};

export default function TableOfContentsItemCreationPane({
  dispatch,
}: Props): JSX.Element {
  return (
    <AttributesPane value={PaneIdentifier.TableOfContentsItemCreation}>
      <AttributesPaneControls dispatch={dispatch} />
      TOC Item Creation Pane
    </AttributesPane>
  );
}
