import { Icon } from "@mdi/react";
import { mdiClose } from "@mdi/js";
import { Button } from "@acdc2/ui/components/button";
import { Dispatch } from "react";
import { useTranslation } from "react-i18next";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@acdc2/ui/components/tooltip";
import { EditorAction, TabIdentifier, PaneIdentifier } from "../reducers";
import SidebarDivider from "../../shell/SidebarDivider";

type Props = {
  dispatch: Dispatch<EditorAction>;
};

export default function AttributesPaneControls({
  dispatch,
}: Props): JSX.Element {
  const { t } = useTranslation();

  const onClick = () => {
    dispatch({
      type: "switchPane",
      paneState: {
        tabIdentifier: TabIdentifier.Attributes,
        paneIdentifier: PaneIdentifier.Default,
      },
    });
  };

  return (
    <div>
      <div className="p-2">
        <TooltipProvider delayDuration={0}>
          <Tooltip>
            <TooltipTrigger asChild>
              <Button variant="outline" size="icon" onClick={onClick}>
                <Icon path={mdiClose} size={1} />
              </Button>
            </TooltipTrigger>
            <TooltipContent side="right">
              {t("AttributesPaneControls.cancelTooltip")}
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      </div>
      <SidebarDivider />
    </div>
  );
}
