import { useTranslation } from "react-i18next";
import { PagesOverviewSidebarTabContentFragment } from "../../client/generated";
import SidebarTabContent from "../../shell/SidebarTabContent";
import { TabIdentifier } from "../reducers";
import SidebarDivider from "../../shell/SidebarDivider";
import PageThumbnail from "./PageThumbnail";
import PagesOverviewNavigationInput from "./PagesOverviewNavigationInput";

type Props = {
  fragment: PagesOverviewSidebarTabContentFragment;
};

export default function PagesOverviewSidebarTabContent({
  fragment,
}: Props): JSX.Element {
  const { t } = useTranslation();

  return (
    <SidebarTabContent
      value={TabIdentifier.Pages}
      title={t("PagesOverviewSidebarTabContent.title")}
    >
      <div className="flex-1 flex-col flex gap-4 p-4 overflow-auto">
        {fragment.document.pages.pages.map((page) => (
          <PageThumbnail key={page.id} fragment={page} />
        ))}
      </div>
      <div>
        <SidebarDivider />
        <PagesOverviewNavigationInput key={fragment.id} fragment={fragment} />
      </div>
    </SidebarTabContent>
  );
}
