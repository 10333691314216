import { Dispatch } from "react";
import { AttributesListFragment } from "../../client/generated";
import { EditorAction } from "../reducers";
import AttributesListItem from "./AttributesListItem";

type Props = {
  fragment: AttributesListFragment;
  dispatch: Dispatch<EditorAction>;
};

export default function AttributesList({
  fragment,
  dispatch,
}: Props): JSX.Element {
  return (
    <ol>
      {fragment.attributes.map((attribute) => (
        <AttributesListItem
          key={attribute.id}
          fragment={attribute}
          dispatch={dispatch}
        />
      ))}
    </ol>
  );
}
