import { Dispatch } from "react";
import { EditorAction, PaneIdentifier } from "../../reducers";
import AttributesPane from "../AttributesPane";
import AttributesPaneControls from "../AttributesPaneControls";

type Props = {
  dispatch: Dispatch<EditorAction>;
};

export default function TableHeaderCellCreationPane({
  dispatch,
}: Props): JSX.Element {
  return (
    <AttributesPane value={PaneIdentifier.TableHeaderCellCreation}>
      <AttributesPaneControls dispatch={dispatch} />
      Table Header Cell Creation Pane
    </AttributesPane>
  );
}
