import { useTranslation } from "react-i18next";
import { Accordion } from "@acdc2/ui/components/accordion";
import { MetadataSidebarTabContentFragment } from "../../client/generated";
import { TabIdentifier } from "../reducers";
import SidebarTabContent from "../../shell/SidebarTabContent";
import MetadataAuthorAccordionItem from "./MetadataAuthorAccordionItem";
import MetadataKeywordAccordionItem from "./MetadataKeywordAccordionItem";
import MetadataDateAccordionItem from "./MetadataDateAccordionItem";
import MetadataTitleAccordionItem from "./MetadataTitleAccordionItem";

type Props = {
  fragment: MetadataSidebarTabContentFragment;
};

export default function MetadataSidebarTabContent({
  fragment,
}: Props): JSX.Element {
  const { t } = useTranslation();

  return (
    <SidebarTabContent
      value={TabIdentifier.Metadata}
      title={t("MetadataSidebarTabContent.title")}
    >
      <Accordion
        type="multiple"
        defaultValue={["titles", "authors", "keywords", "date"]}
        className="w-full"
      >
        <MetadataTitleAccordionItem fragment={fragment} />
        <MetadataAuthorAccordionItem fragment={fragment} />
        <MetadataKeywordAccordionItem fragment={fragment} />
        <MetadataDateAccordionItem fragment={fragment} />
      </Accordion>
    </SidebarTabContent>
  );
}
