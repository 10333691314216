import { Dispatch } from "react";
import { EditorAction, PaneIdentifier } from "../../reducers";
import AttributesPane from "../AttributesPane";
import AttributesPaneControls from "../AttributesPaneControls";

type Props = {
  dispatch: Dispatch<EditorAction>;
};

export default function CaptionCreationPane({ dispatch }: Props): JSX.Element {
  return (
    <AttributesPane value={PaneIdentifier.CaptionCreation}>
      <AttributesPaneControls dispatch={dispatch} />
      Caption Creation Pane
    </AttributesPane>
  );
}
